const config = {
    aws_project_region: process.env.REACT_APP_COGNITO_REGION,
    aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
    "oauth": {
        "domain": process.env.REACT_APP_COGNITO_DOMAIN,
        "scope": ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
        "clientID": process.env.REACT_APP_COGNITO_CLIENT_ID,
        "redirectSignIn": `${window.location.origin}/login`,
        "redirectSignOut": `${window.location.origin}/login`,
        "responseType": "code",
    },
    federationTarget: 'COGNITO_USER_POOLS',
};
export default config;