import axios from "axios";
import { ACCESS_TOKEN } from "constants/config";

axios.defaults.baseURL = process.env.REACT_APP_API_URI_END_POINT;

//axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

//axios.defaults.headers.common["X-From"] = window.location.href;
//axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

axios.interceptors.request.use(
  function (axios_config: any) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    axios_config.headers.Authorization = `Bearer ${token}`; 
    return axios_config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axios;
