import { dataGeneralisedSoil } from "../actionTypes";

const initialState = {
  dataGeneralised: [],
};

const dataGeneralised = (state = initialState, action) => {
  switch (action.type) {
    case dataGeneralisedSoil.SET_LIST_DATA_GENERALISED_SOIL:
      return {
        ...state,
        dataGeneralised: action.payload,
      };
    default:
      return state;
  }
};

export default dataGeneralised;
