import { geologicalFormationType } from "store/actionTypes";

const initialState = {
  listGeologicalFormation: [],
};

const geologicalFormation = (state = initialState, action) => {
  switch (action.type) {
    case geologicalFormationType.SET_LIST_DATA_GEOLOGICAL_FORMATION:
      return { listGeologicalFormation: action.payload };
    default:
      return state;
  }
};

export default geologicalFormation;
