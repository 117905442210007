import { DataMapViewer } from "schema/MapViewer";
import { DataSummaryPoint } from "schema/DataSummaryPoint";
import { mapViewerActionType } from "../actionTypes";

const initialState = {
  isLoadMapviewerloading: false,
  isSearch: false,
  isSelectTools: false,
  isUnline: false,
  isClear: false,
  errorMessage: "",
  data: {
    dataMapViewerInits: [] as DataMapViewer[],
    dataSummaryPoints: [] as DataSummaryPoint[],
    dataMapViewers: [] as DataMapViewer[],
    dataPointsSelected: [] as DataMapViewer[],
    dataPointsSelectedUntick: [] as DataMapViewer[],
  },
  bounds: undefined,
  dataPointFilter: undefined,
  dataMapViewers: [] as DataMapViewer[],
};

const datamap = (state = initialState, action) => {
  switch (action.type) {
    case mapViewerActionType.GET_LIST_POINS:
      return {
        ...state,
        data: action.payload.dataMap,
        isLoadMapviewerloading: action.isLoadSuccess,
        // isSearch: true,
        isSelectTools: false,
        isUnline: false,
        bounds: action.payload.bounds,
      };
    case mapViewerActionType.GET_SELECTION_POINTS:
      return {
        ...state,
        errorMessage: "",
        data: action.payload,
        isLoadMapviewerloading: action.isLoadSuccess,
        // isSearch: false,
        isSelectTools: true,
        isUnline: false,
        isClear: false,
      };
    case mapViewerActionType.QUERY_DATA_FILTER_MAP:
      return {
        ...state,
        errorMessage: "",
        data: action.payload,
        isLoadMapviewerloading: action.isLoadSuccess,
        // isSearch: true,
        isSelectTools: false,
        isUnline: false,
        isClear: false,
      };
    case mapViewerActionType.SET_UNLINE_AND_OPACITY_DATA_SUMMARY:
      return {
        ...state,
        errorMessage: "",
        data: action.payload,
        isLoadMapviewerloading: action.isLoadSuccess,
        // isSearch: false,
        isSelectTools: false,
        isUnline: true,
        isClear: false,
      };
    case mapViewerActionType.CLEAR_MULTIPLE_POINTS:
      return {
        ...state,
        errorMessage: "",
        data: action.payload,
        isLoadMapviewerloading: action.isLoadSuccess,
        // isSearch: false,
        isSelectTools: false,
        isUnline: false,
        isClear: true,
      };
    case mapViewerActionType.ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload,
      };
    case mapViewerActionType.IS_LOAD_MAP:
      return {
        ...state,
        isLoadMapviewerloading: action.payload,
      };
    case mapViewerActionType.UPDATE_MAP_FILTERED:
      return {
        ...state,
        isSearch: action.payload,
      };
    case mapViewerActionType.UPDATE_BOUND_MAP:
      return {
        ...state,
        bounds: action.payload,
      };
    case mapViewerActionType.UPDATE_DATA_POINT_FILTER:
      return {
        ...state,
        dataPointFilter: action.payload,
      };
    case mapViewerActionType.UPDATE_DATA_MAPVIEWERS:
      return {
        ...state,
        dataMapViewers: action.payload,
      };
    default:
      return state;
  }
};

export default datamap;
