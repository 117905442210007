import { projectActionType } from "../actionTypes";

export const initialProject = {
  projectid: null,
  projectnumber: null,
  projectname: "",
  clientname: "",
  elevationdatum: "",
  coordinatesystem: "",
  version: "",
  bounds: undefined,
  selected_investigations: "",
};

const initialState = {
  dataTabs: [],
  project: initialProject,
  isLoadProjectSuccess: true,
  errorAddProject: "",
  count: undefined,
  dataSelectedInvestigations: "",
  dataSelectedInvestigationsChange: "",
  projectNumberTitle: "Edit project",
};

const project = (state = initialState, action) => {
  switch (action.type) {
    case projectActionType.GET_TABS:
      return {
        ...state,
        dataTabs: action.payload,
      };
    case projectActionType.ADD_PROJECT_SUCCESS:
      return {
        ...state,
        isAddUserSuccess: action.payload,
      };
    case projectActionType.GET_LIST:
      return {
        ...state,
        project: action.payload,
      };
    case projectActionType.SET_SELECTION_POINTS:
      return {
        ...state,
        dataSelectedInvestigations: action.payload.dataSelectedInvestigations,
        dataSelectedInvestigationsChange:
          action.payload.dataSelectedInvestigationsChange,
      };
    case projectActionType.ADD_PROJECT:
      return {
        ...state,
        errorAddProject: "",
        project: action.payload.project,
        dataSelectedInvestigations: action.payload.dataSelectedInvestigations,
        dataSelectedInvestigationsChange:
          action.payload.dataSelectedInvestigationsChange,
      };
    case projectActionType.ERROR_ADD_PROJECT:
      return {
        ...state,
        errorAddProject: action.payload,
      };
    case projectActionType.GET_BY_ID:
      return {
        ...state,
        project: action.payload.project,
        isLoadProjectSuccess: action.payload.isLoadProjectSuccess,
        dataSelectedInvestigations: action.payload.dataSelectedInvestigations,
        dataSelectedInvestigationsChange:
          action.payload.dataSelectedInvestigationsChange,
      };
    case projectActionType.SET_COUNT_AUTO_SAVE_PROJECT:
      return {
        ...state,
        count: action.payload,
      };
    case projectActionType.SET_PROJECT_NUMBER:
      return {
        ...state,
        projectNumberTitle: action.payload,
      };
    default:
      return state;
  }
};

export default project;
