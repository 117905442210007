import { dataMajorFractionActionType } from "store/actionTypes";

const initialState = {
  listMajorFraction: [],
};

const dataMajorFraction = (state = initialState, action) => {
  switch (action.type) {
    case dataMajorFractionActionType.SET_LIST_DATA_MAJOR_FRACTION:
      return { listMajorFraction: action.payload };
    default:
      return state;
  }
};

export default dataMajorFraction;
