import { userActionType } from "../actionTypes";

const initialState = {
  users: [],
  user: {},
  isAddUserSuccess: "",
  errorAddUser: ""
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case userActionType.GET_LIST:
      return {
        ...state,
        users: action.payload
      }
    case userActionType.ADD_USER:
        return {
            ...state
        }
    case userActionType.ADD_USER_SUCCESS:
        return {
            ...state,
            isAddUserSuccess: action.payload
        }
    case userActionType.ERROR_ADD_USER:
        return {
            ...state,
            errorAddUser: action.payload,
        }
    default:
      return state
  }
}

export default user;
