import { Router } from "react-router-dom";
import Routes from "./routes";
import store from "./store";
import { Provider } from "react-redux";
import { history } from "helpers/common";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./assets/css/app.css";
import { ToastContainer } from "react-toastify";
import { Amplify } from "aws-amplify";
import config from "./AWSExports.js";

Amplify.configure(config);

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Routes />
        <ToastContainer />
      </Router>
    </Provider>
  );
};

export default App;
