import { DataComment } from "schema/Comment";
import { commentActionType } from "../actionTypes";

const initialState = {
  isLoadCommentSuccess: false,
  errorComment: "",
  comments: [] as DataComment[],
};

const comment = (state = initialState, action) => {
  switch (action.type) {
    case commentActionType.IS_LOAD_COMMENT_SUCCESS:
      return {
        ...state,
        isLoadCommentSuccess: action.payload,
      };
    case commentActionType.GET_COMMENTS_PROJECT:
      return {
        ...state,
        errorComment: "",
        isLoadCommentSuccess: false,
        comments: action.payload,
      };
    case commentActionType.REGISTER_COMMENT:
      return {
        ...state,
        errorComment: "",
        comment: action.payload,
      };
    default:
      return state;
  }
};

export default comment;
