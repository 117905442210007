const PATHS = {
  HOMEPAGE: "/",
  LIST_PROJECTS: "/projects",
  LIST_PROJECTS_HISTORY: "/projects-history",
  PROJECT: "/project/:id",
  NEW_PROJECT: "/project/new",
  EDIT_PROJECT: "/project/:id/edit",
  RAW_DATA_DETAIL_PAGE: "/raw-data/:idpoint/detail/:id/edit",
  GEOTECHNICAL_DETAIL_PAGE:
    "/geotechnical/:idpointGeotechnical/detail/:id/edit",
  PROJECT_NEW: "/project/new",
  DATA_PARENT_ANALYTICS_DETAIL:
    "/data-parent-analytics/:idDataParentAnalytics/:id/edit",
  DATA_CHILD_ANALYTICS_DETAIL:
    "/data-child-analytics/:idDataChildAnalytics/:id/edit",
  DATA_CHILD_ANALYTICS_DETAIL_PDF:
    "/data_child_analytics_pdf/:idDataChildAnalytics/:id/edit",
  EXPORT_TO_PDF: "/export/pdf/:id/edit",
  EXPORT_TO_EXCEL: "/export/excel/:id/edit",
  ACCOUNT: "/account",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
};

export default PATHS;
