import { graphDAAType } from "../actionTypes";

const initialState = {
  graphParameter: [],
  graphCalculation: [],
  colorTraces: [],
  modeTrace: [],
  isChangeType: false,
  isCallAPIPlot: false,
};

const graphDAA = (state = initialState, action) => {
  switch (action.type) {
    case graphDAAType.SET_LIST_GRAPH_PARAMETER:
      return {
        ...state,
        graphParameter: action.payload,
      };
    case graphDAAType.SET_LIST_GRAPH_CALCULCATION:
      return {
        ...state,
        graphCalculation: action.payload,
      };
    case graphDAAType.SET_COLOR_TRACE:
      return {
        ...state,
        colorTraces: action.payload,
      };
    case graphDAAType.SET_MODE_TRACE:
      return {
        ...state,
        modeTrace: action.payload,
      };
    case graphDAAType.SET_IS_CHANGE_TYPE:
      return {
        ...state,
        isChangeType: action.payload,
      };
    case graphDAAType.SET_IS_CALL_API_PLOT:
      return {
        ...state,
        isCallAPIPlot: action.payload,
      };
    default:
      return state;
  }
};

export default graphDAA;
