import { dataGeotechnicalActionType } from "../actionTypes";

const initialState = {
  isLoadDataGeotechnical: false,
  dataGeotechnical: [],
  idGeotechnical: undefined,
  dataOptionsGeotechnicalUnit: [],
  listAssigneGeotechnicalUnit: [],
};

const dataGeotechnical = (state = initialState, action) => {
  switch (action.type) {
    case dataGeotechnicalActionType.IS_LOAD_DATA_GEOTECHNICAL_SUCCESS:
      return {
        ...state,
        isLoadDataGeotechnical: action.payload,
      };
    case dataGeotechnicalActionType.SET_LIST_DATA_GEOTECHNICAL:
      return {
        ...state,
        dataGeotechnical: action.payload.data,
        dataOptionsGeotechnicalUnit: action.payload.dataOptions,
      };
    case dataGeotechnicalActionType.SET_ID_DATA_GEOTECHNICAL:
      return {
        ...state,
        idGeotechnical: action.payload,
      };
    case dataGeotechnicalActionType.SET_LIST_ASIGNEE_DATA_GEOTECHNICAL:
      return {
        ...state,
        listAssigneGeotechnicalUnit: action.payload,
      };
    case dataGeotechnicalActionType.SET_DATA_GEOTECHNICAL:
      return {
        ...state,
        dataGeotechnical: action.payload.data,
        dataOptionsGeotechnicalUnit: action.payload.dataOptions,
      };
    default:
      return state;
  }
};

export default dataGeotechnical;
