import { dataSummaryActionType } from "../actionTypes";

const initialState = {
  isLoadDataSummary: false,
  dataSummary: [],
  listDataSummaryDetail: [],
  idDataSummary: undefined,
};

const dataSummary = (state = initialState, action) => {
  switch (action.type) {
    case dataSummaryActionType.IS_LOAD_DATA_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoadDataSummary: action.payload,
      };
    case dataSummaryActionType.SET_LIST_DATA_SUMMARY:
      return {
        ...state,
        dataSummary: action.payload,
      };
    case dataSummaryActionType.SET_ID_DATA_SUMMARY:
      return {
        ...state,
        idDataSummary: action.payload,
      };
    case dataSummaryActionType.SET_LIST_DATA_SUMMARY_DETAIL:
      return {
        ...state,
        listDataSummaryDetail: action.payload,
      };
    default:
      return state;
  }
};

export default dataSummary;
